/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/





/* img-hover-wraps */
.img-hover-wrap {
	-webkit-transition: transform .3s ease;
	-o-transition: transform .3s ease;
	transition: transform .3s ease;
	-moz-transform-style: flat !important;
	-webkit-backface-visibility: hidden;
}

.img-hover-wrap:hover {
	-webkit-transform: scale(1.06);
	-ms-transform: scale(1.06);
	-o-transform: scale(1.06);
	transform: scale(1.06);
	z-index: 2;
}

.img-hover-card > a:after {
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(0deg, rgba(0,17,36,0.2987570028011205) 0%, rgba(255,255,255,0) 55%);
	z-index: 0;
	-webkit-transition: background-color .3s ease;
	-o-transition: background-color .3s ease;
	transition: background-color .3s ease;
}

.img-hover-wrap:hover .img-hover-card > a:after { background-color: rgba(0, 0, 0, 0.6); }

.img-hover-card {
	position: relative;
	display: block;
	will-change: transform;
	transition: all .3s ease;
	box-shadow: 0 10px 40px transparent;
}

.img-hover-card a {
	width: 100%;
	max-width: 100%;
	display: block;
	z-index: 99;
}

.img-hover-card.hover-in {
	transition: -webkit-transform .2s ease-out;
	transition: transform .2s ease-out;
	transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.img-hover-card.hover-3d { z-index: 2; }

.img-hover-card.hover-out {
	transition: -webkit-transform .2s ease-in;
	transition: transform .2s ease-in;
	transition: transform .2s ease-in, -webkit-transform .2s ease-in;
}

.img-hover-wrap:hover .img-hover-card { box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4); }

.img-hover-wrap .img-hover-detail {
	position: absolute;
	top: auto;
	left: 40px;
	bottom: 40px;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-webkit-transform: scale(1) translateY(40px) translateZ(50px);
	-ms-transform: scale(1) translateY(30px) translateZ(50px);
	-o-transform: scale(1) translateY(30px) translateZ(50px);
	transform: scale(1) translateY(30px) translateZ(50px);
	-webkit-transition: transform .2s ease;
	-o-transition: transform .2s ease;
	transition: transform .2s ease;
}

.img-hover-wrap:hover .img-hover-detail {
	opacity: 1;
	-webkit-transform: scale(1.06) translateY(-5px) translateZ(50px);
	-ms-transform: scale(1.06) translateY(-5px) translateZ(50px);
	-o-transform: scale(1.06) translateY(-5px) translateZ(50px);
	transform: scale(1.06) translateY(-5px) translateZ(50px);
}

.img-hover-wrap .img-hover-title {
	margin: 0 0 6px 0;
	font-size: 17px;
	font-weight: 600;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	z-index: 9;
}

.img-hover-wrap .img-link {
	opacity: 0;
	width: 20px;
	height: 20px;
	border: 1px solid #FFF;
	border-radius: 50%;
	text-align: center;
	line-height: 18px;
	font-size: 11px;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

.img-hover-wrap:hover .img-link { opacity: 1; }

.img-hover-detail a { color: #FFF; }


.demos-filter {
	margin: 0;
	text-align: right;
}

.demos-filter li {
	list-style: none;
	margin: 10px 0px;
}

.demos-filter li a {
	display: block;
	border: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #444;
}

.demos-filter li a:hover,
.demos-filter li.activeFilter a { color: #1ABC9C; }

@media (max-width: 991px) {
	.demos-filter { text-align: center; }

	.demos-filter li {
		float: left;
		width: 33.3%;
		padding: 0 20px;
	}
}

@media (max-width: 767px) { .demos-filter li { width: 50%; } }

#portfolio_wrapper .tp-loader.spinner3{ background-color: #313437 !important; } 

#portfolio .uranus.tparrows{width:50px; height:50px; background:rgba(255,255,255,0)}#portfolio .uranus.tparrows:before{width:50px; height:50px; line-height:50px; font-size:40px; transition:all 0.3s;-webkit-transition:all 0.3s}#portfolio .uranus.tparrows:hover:before{opacity:0.75}

@media (max-width: 991.98px) {
	#header.dark.transparent-header, #header.dark.semi-transparent {
   
		background: #003543 !important;
	}
}

section#page-title:after {
	content: '';
	position: absolute;
	background: #01364461!important;
	width: 100%;
	height: 100%;
	top: 0%;
	left:0;
	z-index: 0;
}

.particles-js-canvas-el {
	position: absolute;
	top: 0%;
	left:0;
}

#pricing {

	.selected-light {
		background: #ffffff8f;
	}

	.selected-success {
		background: #21a9af63;
	}

	.selected-success .service-heading {
		color: #003847 !important;
	}

	.service-item {
		padding-top: 15px;
	}

	.selected-success p {
		color: #000000 !important;
	}
	.page-title {
		padding-right: 0px;
		padding-left: 10px;
	}

	.single-page-content {
		padding: 2rem 3rem 2rem 3rem;
	}

	.section-heading {
		font-size:2.5em;
		font-weight: 500 !important;
		margin-top:0;
		margin-bottom:15px;
	}

	.service-heading {
		width: fit-content;
		margin-left: auto !important;
		margin-right: auto !important;
		padding-top: 10px;
	}

	#estimate-result {
		margin-top: 10px;
		margin-bottom: 30px;
	}

	.hidden {
		display: none !important;
	}

	#save-estimate {
		margin-bottom: 15px;
	}
}


.page-title-muted { 
    color: #cfdeec !important;
}

#logo {
	display: flex;
	align-content: center;
	align-items: center;
	margin-left: 20px !important;
}

@media (max-width: 991px) {
	#logo img {
    	height: 70px !important;
	}

	#header-wrap .container {

	}

}

@media (max-width: 374px) {
	#logo img {
    	height: 56px !important;
	}

	#logo {
		margin-left: 20px !important;
	}


}